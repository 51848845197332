<template>
  <div class="unauthorised">

    <h4 class="uk-card-title uk-center uk-text-center uk-margin-remove">
      <span v-if=" ! logoSrc"
            class="uk-logo uk-center uk-text-center">[Company logo]</span>
      <span v-else=" ! logoSrc"
            :style="logoStyle"
            class="uk-logo uk-center uk-text-center"></span>
    </h4>
    <div class="uk-card-body">

      <h3 class="uk-center uk-text-center">
        <slot name="subTitle">You are not authorised to use this resource.</slot>
      </h3>
      <div class="uk-flex uk-flex-center">
        <form-button @click="$router.push({path: '/'})" icon="home">Go to homepage</form-button>
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    props: {
      /**
       * Relative path for the logo
       * If false, will use placeholder
       **/
      logoSrc: {
        type: [String, Boolean],
        default: '/'
      },
    },
    data () {
      return {};
    },
    computed: {
      logoStyle () {
        if ( ! this.logoSrc) {
          return [];
        }

        let url = utilities.requireAsset(this.logoSrc);
        return {
          'backgroundImage' : "url('"+url+"')",
          'height': '80px',
          'width': '80px',
          'display': 'inline-block'
        };
      },
    }
  };
</script>
